// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-page-front-page-js": () => import("./../../../src/templates/page/FrontPage.js" /* webpackChunkName: "component---src-templates-page-front-page-js" */),
  "component---src-templates-page-page-js": () => import("./../../../src/templates/page/Page.js" /* webpackChunkName: "component---src-templates-page-page-js" */),
  "component---src-templates-page-posts-page-js": () => import("./../../../src/templates/page/PostsPage.js" /* webpackChunkName: "component---src-templates-page-posts-page-js" */),
  "component---src-templates-posts-single-js": () => import("./../../../src/templates/posts/single.js" /* webpackChunkName: "component---src-templates-posts-single-js" */)
}

